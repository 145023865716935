import React from 'react';
import { List, ListItem, ListItemText, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

function SidePanel({ onSelectView }) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState({ licenses: true, tasks: true, requests: true, gdc: true, tests: true });

    const handleClick = (section) => {
        setOpen((prevOpen) => ({
            ...prevOpen,
            [section]: !prevOpen[section],
        }));
    };

    return (
        <div style={{ width: '250px', borderRight: '1px solid #ddd', height: '100vh' }}>
            <List>
                <ListItem button onClick={() => handleClick('licenses')} style={{ backgroundColor: '#f0f0f0' }}>
                    <ListItemText primary={t('Companies and Licenses')} />
                    {open['licenses'] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open['licenses']} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={() => onSelectView('organizations')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Organizations')} />
                        </ListItem>
                        <ListItem button onClick={() => onSelectView('companies')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Companies')} />
                        </ListItem>
                        <ListItem button onClick={() => onSelectView('licenses')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Licenses')} />
                        </ListItem>
                        <ListItem button onClick={() => onSelectView('licensesHistory')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('History')} />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button onClick={() => handleClick('tasks')} style={{ backgroundColor: '#f0f0f0' }}>
                    <ListItemText primary={t('Tasks')} />
                    {open['tasks'] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open['tasks']} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={() => onSelectView('tasksHistory')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Tasks history')} />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button onClick={() => handleClick('requests')} style={{ backgroundColor: '#f0f0f0' }}>
                    <ListItemText primary={t('Requests')} />
                    {open['requests'] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open['requests']} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={() => onSelectView('requests')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Service requests')} />
                        </ListItem>
                        <List component="div" disablePadding>
                        <ListItem button onClick={() => onSelectView('analysisData')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Analysis Data')} />
                        </ListItem>
                    </List>
                    </List>
                </Collapse>

                <ListItem button onClick={() => handleClick('gdc')} style={{ backgroundColor: '#f0f0f0' }}>
                    <ListItemText primary={t('GDC')} />
                    {open['gdc'] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open['gdc']} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={() => onSelectView('gdcRoutes')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Routes')} />
                        </ListItem>
                        <ListItem button onClick={() => onSelectView('gdcUsers')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Users')} />
                        </ListItem>
                        <ListItem button onClick={() => onSelectView('gdcHistory')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Requests')} />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button onClick={() => handleClick('tests')} style={{ backgroundColor: '#f0f0f0' }}>
                    <ListItemText primary={t('Tests')} />
                    {open['tests'] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open['tests']} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={() => onSelectView('serviceStatus')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Service Status')} />
                        </ListItem>
                        <ListItem button onClick={() => onSelectView('serviceTests')} style={{ paddingLeft: '32px' }}>
                            <ListItemText primary={t('Service Tests')} />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </div>
    );
}

export default SidePanel;