export const dateFormatter = (dateText) => {
    const date = new Date(dateText + 'Z');
    return date.toLocaleString(window.navigator.language);
}

export const shortenString = (str, length) => {
    if (str.length > length) {
        return str.substring(0, length) + '...';
    } else {
        return str;
    }
}