import React,  { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker  } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

// Locales for calendar
import 'dayjs/locale/en';
import 'dayjs/locale/ca';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';

export default function DateTime({label, value, onChange}) {
    const { t, i18n } = useTranslation();
    const dayjs = require('dayjs');
    const [date, setDate] = useState(value ? dayjs(value) : null); 

    useEffect(() => {
        dayjs.locale(i18n.language);
    }, [i18n.language]);

    const handleDateChange = (date) => {
        onChange(date);
        setDate(date);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language} >
            <DateTimePicker 
                label={label} 
                value={date} 
                onChange={handleDateChange} 
                views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}/>
        </LocalizationProvider>
      );
}