import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, DialogTitle,  Button, DialogContent, DialogActions } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

function InvalidUserDialog({open}) {
    
    const { t } = useTranslation();
    const { logout } = useAuth0();

    const handleDialogClose = () => {
    return;
    };

    return (
        <>
            <Dialog open={open} onClose={handleDialogClose}>
                <DialogTitle>{t('Invalid user')}</DialogTitle>
                <DialogContent>
                <Alert severity="error">
                    {t('You don\'t have permission to access this application.')}
                </Alert>
                </DialogContent>
                <DialogActions>
                    <Button primary onClick={logout} color="primary">
                    {t('Logout')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default InvalidUserDialog;