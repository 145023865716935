import React, { useState, useEffect, useContext } from 'react';
import ServiceTestsTable from './ServiceTestsTable';
import { Button, Box, LinearProgress, Container, TextField, FormControl, 
    InputLabel, Select, MenuItem, Grid } from '@mui/material';
import ApiService from '../../services/apiService';
import { UserContext } from '../../contexts/UserProvider';
import { useTranslation } from 'react-i18next';
import AppSnackbar from './../AppSnackbar';

function ServiceTests() {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [collections, setCollections] = useState(null);
    const [versions, setVersions] = useState(null);
    const [tests, setTests] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState(null);
    const [collectionName, setCollectionName] = useState(null);
    const [version, setVersion] = useState(null);

    useEffect(() => {
        requestItems(userInfo?.idToken);
    }, [userInfo?.idToken]);

    const requestItems = async (idToken) => {
        setIsLoading(true);
        try {
            const response = await ApiService.getServiceTests(idToken);
            setCollections(response);
            setCollectionName(response[0]?.name);
            const colVersions = response[0]?.versions;
            setVersions(colVersions);
            setVersion(colVersions?.length > 0 ? colVersions[0] : null);
        } catch (error) {
            setSnackbarInfo({ message: t(error.message), severity: error.severity });
        }
        setIsLoading(false);
    };

    const handleCollectionChange = (value) => {
        setCollectionName(value);
        const colVersions = collections.find(c => c.name === value).versions;
        setVersions(colVersions);
        setVersion(colVersions?.length > 0 ? colVersions[0] : null);
        setTests(null);
    };

    const handleVersionChange = (value) => {
        setVersion(value);
        setTests(null);
    };

    const handleRun = async () => {
        setIsLoading(true);
        try {
            let col = collections.find(c => c.name === collectionName);
            let tests = col.groups.flatMap(g => g.tests.map(t => ({ group: g.name, name: t.name, status: 'pending' })));
            tests.forEach(t => t.status = 'pending');
            setTests(tests);
            
            for (const test of tests) {
                test.status = 'running';
                setTests([...tests]);

                const testRun = { collection: collectionName, version: version, group: test.group, test: test.name };
                const testResult = await ApiService.runTest(userInfo?.idToken, testRun);
                test.status = testResult.success ? 'passed' : 'failed';
                test.resultDescription = testResult.resultDescription;
                test.url = testResult.url;  
                setTests([...tests]);
            }
        }
        catch (error) {
            setSnackbarInfo({ message: t(error.message), severity: error.severity });
        }
        setIsLoading(false);
    };

    return (
        <>
        { isLoading && (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>)}
            <Container maxWidth="xl">
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <h1>{t("Service Tests")}</h1>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <FormControl variant="outlined" fullWidth sx={{ mr: 2 }}>
                        <InputLabel>{t("Collection")}</InputLabel>
                        <Select
                            value={collectionName}
                            onChange={(e) => handleCollectionChange(e.target.value)}
                            label={t("Collection")}
                        >
                            {collections?.map((col) => (
                                <MenuItem key={col.name} value={col.name}>{col.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {versions && (
                        <FormControl variant="outlined" fullWidth sx={{ mr: 2 }}>
                            <InputLabel>{t("Version")}</InputLabel>
                            <Select
                            value={version}
                            onChange={(e) => handleVersionChange(e.target.value)}
                            label={t("Version")}
                        >
                            {versions?.map((v) => (
                                <MenuItem key={v} value={v}>{v}</MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    )}
                    <Button variant='contained' onClick={handleRun} disabled={isLoading} >{t("Run")}</Button>
                </Box>
                <ServiceTestsTable items={tests} />
            </Container>
            <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
        </>
    );
}

export default ServiceTests;