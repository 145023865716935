import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, DialogTitle, Typography, Button, DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

function WelcomeDialog({open}) {

    const { t } = useTranslation();
    const { loginWithRedirect } = useAuth0();
    const [loginDialogDelay, setLoginDialogDelay] = useState(false);

    setTimeout(() => { setLoginDialogDelay(true); }, 1000); 

    const handleDialogClose = () => {
    return;
    };

    return (
        <>
            <Dialog open={loginDialogDelay && open} onClose={handleDialogClose}>
                <DialogTitle>{t('Welcome to Ort.Admin')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{t('Please log in to continue.')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button primary onClick={loginWithRedirect} color="primary">
                    {t('Login')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default WelcomeDialog;