import React from 'react';
import HealthCheck from './HealthCheck';
import RequestChart from './RequestChart';

const ServiceStatus = ({ title, healthCheckUrl, fetchData }) => {
  return (
    <div style={{ 
      display: 'flex', alignItems: 'center', paddingLeft: '20px', 
      border: '1px solid lightgray', borderRadius: '15px', marginBottom: '10px',
      width: 'min-content', paddingRight: '10px' }}>
      <div style={{ flex: '0 1 auto', width: '100px' }}>
        <div style={{paddingBottom: '5px', fontWeight: 'bold'}}>{title}</div>
        <HealthCheck url={healthCheckUrl} />
      </div>
      <div style={{ flex: '0 1 auto' }}>
        {fetchData && <RequestChart fetchData={fetchData} />}
      </div>
    </div>
  );
};

export default ServiceStatus;