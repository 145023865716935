import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { dateFormatter } from '../../controls/Formatters';

function LicensesHistoryTable({ items }) {
    const { t } = useTranslation();
    
    return (
        <div>
            {items &&
            <>
            <TableContainer>
                <Table size='small' padding='small'>
                    <colgroup>
                        <col style={{width:'15%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>{t("Date")}</strong></TableCell>
                            <TableCell><strong>{t("Company Id")}</strong></TableCell>
                            <TableCell><strong>{t("License Name")}</strong></TableCell>
                            <TableCell><strong>{t("Stasus")}</strong></TableCell>
                            <TableCell><strong>{t("Comments")}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(item => (
                            <TableRow key={item.id}>
                                <TableCell>{dateFormatter(item.dateTime)}</TableCell>
                                <TableCell>{item.companyId}</TableCell>
                                <TableCell>{item.licenseName}</TableCell>
                                <TableCell>{item.status}</TableCell>
                                <TableCell>{item.comments}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
            }
        </div>
    );
}

export default LicensesHistoryTable;