import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import { useTranslation } from 'react-i18next';

function UsersTable({ items }) {
    const { t } = useTranslation();

    return (
        <div>
            {items &&
            <TableContainer>
                <Table>
                    <colgroup>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'50%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>{t("CompanyId")}</strong></TableCell>
                            <TableCell><strong>{t("DriverId")}</strong></TableCell>
                            <TableCell><strong>{t("Name")}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(item => (
                            <TableRow key={item.id}>
                                <TableCell>{item.companyId}</TableCell>
                                <TableCell>{item.driverId}</TableCell>
                                <TableCell>{item.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </div>
    );
}

export default UsersTable;