
export default class ApiServiceMock {

    static organizations = [];
    static companies = [];
    static licenses = [];

    // User
    static async getUserInfo(idToken) {
      const base64Url = idToken.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const userInfo = JSON.parse(window.atob(base64));
      const isOnerboxUser = userInfo?.email.endsWith('@onerbox.com');
        return {
          valid: isOnerboxUser,
        };
      };

    // Organizations
    static async getOrganizations(idToken) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      if (this.organizations.length > 0) return this.organizations;
      for (let i = 0; i < 10; i++) {
        const id = i;
        const name = 'Org name ' + (i + 1);
        const description = 'Org description ' + (i + 1);
        this.organizations.push({ id, name, description });
      }
      return this.organizations;
    };

    static async getOrganization(idToken, id) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      let p = this.organizations.find(p => p.id === id);
      return p;
    }

    static async saveOrganization(idToken, data) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      this.organizations.push(data);
      return true;
    }

    static async updateOrganization(idToken, data) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      this.organizations = this.organizations.map(p => p.id === data.id ? data : p);
      return true;
    }

    static async deleteOrganization(idToken, data) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      this.organizations = this.organizations.filter(p => p.id !== data.id);
      return true;
    }

    // Companies
    static async getCompanies(idToken) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      if (this.companies.length > 0) return this.companies;
      const modulesArray = ["ServiceManagement", "PackService", "FreightOptimizer", "SageInterface", "ImportContainersCSV"];
      const detailsArray = ["NumberOfProcesses=100", "HereApi=v8"];
      for (let i = 0; i < 10; i++) {
        const id = i;
        const organization = "Org name " + (i + 1);
        const companyId = "Company id " + (i + 1);
        const gdc = Math.random() < 0.5;
        const smartDelivery = Math.random() < 0.5;
        const smartDeliveryMaps = Math.random() < 0.5;
        const licensesNum = Math.floor(Math.random() * 10);
        const modules = modulesArray.slice(0, Math.floor(Math.random() * modulesArray.length)) ?? [];
        const details = detailsArray.slice(0, Math.floor(Math.random() * detailsArray.length)) ?? [];
        const logo = "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png";
        this.companies.push({ id, organization, companyId, gdc, smartDelivery, smartDeliveryMaps, licensesNum, modules, details, logo });
      }
      return this.companies;
    };

    static async getCompany(idToken, id) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      let p = this.companies.find(p => p.id === id);
      return p;
    }

    static async saveCompany(idToken, data) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      this.companies.push(data);
      return true;
    }

    static async updateCompany(idToken, data) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      this.companies = this.companies.map(p => p.id === data.id ? data : p);
      return true;
    }

    static async deleteCompany(idToken, data) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      this.companies = this.companies.filter(p => p.id !== data.id);
      return true;
    }

    // Licenses
    static async getLicenses(idToken) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      if (this.licenses.length > 0) return this.licenses;
      for (let i = 0; i < 10; i++) {
        const id = i;
        const companyId = "Company id " + (i + 1);
        const name = 'License name ' + (i + 1);
        const key = "F3A9-8714-0DED-49F4-8056-AEA6-94C5-CEB6";
        const enabled = Math.random() < 0.5;;
        const maxRequestsDay = 1000;
        const expirationDate = "2023/12/31";
        const maxVehicles = 100;
        const maxDestinations = 100;
        const modules = "mod";
        const details = "det"
        this.licenses.push({ id, companyId, name, key, enabled, maxRequestsDay, expirationDate, maxVehicles, maxDestinations, modules, details });
      }
      return this.licenses;
    };

    static async getLicense(idToken, id) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      let p = this.licenses.find(p => p.id === id);
      return p;
    }

    static async saveLicense(idToken, data) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      this.licenses.push(data);
      return true;
    }

    static async updateLicense(idToken, data) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      this.licenses = this.licenses.map(p => p.id === data.id ? data : p);
      return true;
    }

    static async deleteLicense(idToken, data) {
      if (!idToken) return null;
      await new Promise(resolve => setTimeout(resolve, 200));
      this.licenses = this.licenses.filter(p => p.id !== data.id);
      return true;
    }
}
