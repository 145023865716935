import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ServiceTestsTable({ items }) {
    const { t } = useTranslation();

    const getStatusChip = (status) => {
        switch (status) {
            case 'running':
                return <Chip size='small' label="Running" style={{ backgroundColor: 'lightyellow' }} />;
            case 'passed':
                return <Chip size='small' label="Passed" style={{ backgroundColor: 'lightgreen' }} />;
            case 'failed':
                return <Chip size='small' label="Failed" style={{ backgroundColor: 'red', color: 'white' }} />;
            default:
                return <Chip size='small' label="Pending" style={{ backgroundColor: 'lightgray' }} />;
        }
    };
    
    const groupedItems = items?.reduce((groups, item) => {
        const group = item.group || 'Ungrouped';
        if (!groups[group]) {
            groups[group] = [];
        }
        groups[group].push(item);
        return groups;
    }, {});
    
    return (
        <div>
            {items &&
            <>
            <TableContainer>
                <Table size='small' padding='small'>
                    <colgroup>
                        <col style={{width:'40%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'20%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>{t("Test")}</strong></TableCell>
                            <TableCell><strong>{t("Satus")}</strong></TableCell>
                            <TableCell><strong>{t("Result")}</strong></TableCell>
                            <TableCell><strong>{t("Url")}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(groupedItems).map(group => (
                                <React.Fragment key={group}>
                                    <TableRow>
                                        <TableCell colSpan={4} style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                                            {group}
                                        </TableCell>
                                    </TableRow>
                                    {groupedItems[group].map((item, index) => (
                                       <TableRow>
                                       <TableCell>{item.name}</TableCell>
                                       <TableCell>{getStatusChip(item.status)}</TableCell>
                                       <TableCell>{item.resultDescription}</TableCell>
                                       <TableCell><a href={item.url} target='_blank'>Open</a></TableCell>
                                   </TableRow>
                                    ))}
                                </React.Fragment>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
            }
        </div>
    );
}

export default ServiceTestsTable;