import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import ApiService from '../../../services/apiService';
import AppSnackbar from './../../AppSnackbar';
import { UserContext } from '../../../contexts/UserProvider';
import DeleteButton from '../../ConfirmButton';

function OrganizationForm({ open, onClose, onAccept, editItem }) {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [item, setItem] = useState(null);
    const [snackbarInfo, setSnackbarInfo] = useState(null);
    const [saving, setSaving] = useState(false);
    const [isNew, setIsNew] = useState(true);
    const [nameError, setNameError] = useState('');

    useEffect(() => {
        resetForm();
        if (editItem) {
            setItem(editItem);
            setIsNew(false);
        }
    }, [editItem]);

    const validateForm = () => {
        if (!item?.name) {
            setNameError('Name is required.');
            return false;
        }
        setNameError('');
        return true;
    };

    const handleSave = async () => {
        if (!validateForm()) return;

        setSaving(true);
        try {
            if (isNew) {
                await ApiService.saveOrganization(userInfo?.idToken, item);
                setSnackbarInfo({message: t('Organization created.'), severity: 'success'});
            } else {
                await ApiService.updateOrganization(userInfo?.idToken, item);
                setSnackbarInfo({message: t('Organization updated.'), severity: 'success'});
            }
            onAccept(item);
            handleClose();
        }
        catch(error)
        {
            setSnackbarInfo({ message: t(error.message), severity: error.severity});
            setSaving(false);
        }
    };

    const handleDelete = async () => {
        setSaving(true);
        try {
            await ApiService.deleteOrganization(userInfo.idToken, item);
            onAccept(null);
            handleClose();
            setSnackbarInfo({message: t('Deleted successfully.'), severity: 'success'});
        } 
        catch (error) {
            setSnackbarInfo({message: t(error.message), severity: error.severity});
            setSaving(false);
        }
    }

    const handleClose = () => {
        resetForm();
        onClose();
    }

    const resetForm = () => {
        setItem({ id: 0, name: '', description: ''});
        setSaving(false);
        setIsNew(true);
        setNameError('');
    }

    return (
    <>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Organization</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Name"
                    type="text"
                    fullWidth
                    value={item?.name || ''}
                    onChange={(e) => setItem({...item, name: e.target.value})}
                    error={!!nameError}
                    helperText={nameError}
                />
                <TextField
                    margin="dense"
                    label="Description"
                    type="text"
                    fullWidth
                    value={item?.description || ''}
                    onChange={(e) => setItem({...item, description: e.target.value})}
                />
            </DialogContent>
            <DialogActions>
               { !isNew && !saving && (<DeleteButton 
                    variant="contained"
                    color="error"
                    onConfirm={handleDelete}
                    msgTitle={t("Delete organization")}
                    msgText={t("Are you sure you want to delete?")}>
                    {t('Delete')}
                </DeleteButton>)}
                <Button onClick={handleClose} color="primary" disabled={saving}>
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" disabled={saving}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
    </>
    );
}

export default OrganizationForm;