import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import ApiService from '../../../services/apiService';
import AppSnackbar from './../../AppSnackbar';
import { UserContext } from '../../../contexts/UserProvider';
import DeleteButton from '../../ConfirmButton';
import JsonView from '@uiw/react-json-view';

function RouteForm({ open, onClose, onAccept, editItem }) {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [item, setItem] = useState(null);
    const [snackbarInfo, setSnackbarInfo] = useState(null);
    const [saving, setSaving] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [json, setJson] = useState(null);

    useEffect(() => {
        resetForm();

        if (editItem) {
            setItem(editItem);
            setIsNew(false);
        }
        else {
            setIsNew(true);
        }
    }, [editItem]);

    const handleSave = async () => {
        setSaving(true);
        try {
            if (isNew) {
                const routeRequest = {
                    companyId: item.companyId,
                    routeId: item.routeId,
                    name: item.name,
                    routeString: json
                }
                await ApiService.saveGdcRoute(userInfo?.idToken, routeRequest);
                setSnackbarInfo({message: t('Route created.'), severity: 'success'});
                onAccept(item);
                handleClose();
            }
        }
        catch(error)
        {
            setSnackbarInfo({ message: t(error.message), severity: error.severity});
            setSaving(false);
        }
    };

    const handleDelete = async () => {
        setSaving(true);
        try {
            await ApiService.deleteGdcRoute(userInfo.idToken, item.companyId, item.routeId);
            onAccept(null);
            handleClose();
            setSnackbarInfo({message: t('Deleted successfully.'), severity: 'success'});
        } 
        catch (error) {
            setSnackbarInfo({message: t(error.message), severity: error.severity});
            setSaving(false);
        }
    }

    const handleSimulateLocations = async () => {
        setSaving(true);
        try {
            await ApiService.simulateUserLocations(userInfo.idToken, item.companyId, item.routeId);
            const loadedItem = await ApiService.getGdcRoute(userInfo.idToken, item.companyId, item.routeId);
            setItem(loadedItem);
            setSnackbarInfo({message: t('Simulated locations created.'), severity: 'success'});
        } 
        catch (error) {
            setSnackbarInfo({message: t(error.message), severity: error.severity});
            setSaving(false);
        }
    }

    const handleClose = () => {
        resetForm();
        onClose();
    }

    const resetForm = () => {
        setItem({ companyId: 'ORB', routeId: 0, name: '', routeString: '' });
        setSaving(false);
    }

    return (
    <>
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle>GDC Route</DialogTitle>
            <DialogContent>
                <TextField
                    disabled={!isNew}
                    autoFocus
                    margin="dense"
                    label="Company Id"
                    type="text"
                    fullWidth
                    value={item?.companyId || ''}
                    onChange={(e) => setItem({...item, companyId: e.target.value})}
                />
                <TextField
                    disabled={!isNew}
                    autoFocus
                    margin="dense"
                    label="Route Id"
                    type="text"
                    fullWidth
                    value={item?.routeId || ''}
                    onChange={(e) => setItem({...item, routeId: e.target.value})}
                />
                <TextField
                    disabled={!isNew}
                    autoFocus
                    margin="dense"
                    label="Name"
                    type="text"
                    fullWidth
                    value={item?.name || ''}
                    onChange={(e) => setItem({...item, name: e.target.value})}
                />
                { !isNew && (<JsonView value={item} style={{padding: '10px'}} collapsed={1} />)}
                { isNew && (
                    <TextField type="text" multiline fullWidth
                    value={json}
                    onChange={(e) => setJson(e.target.value)}
                    ></TextField>
                    )}
            </DialogContent>
            <DialogActions>
               { !isNew && !saving && (<DeleteButton 
                    variant="contained"
                    color="error"
                    onConfirm={handleDelete}
                    msgTitle={t("Delete Route")}
                    msgText={t("Are you sure you want to delete?")}>
                    {t('Delete')}
                </DeleteButton>)}
                {/* { !isNew && (<Button 
                    onClick={handleSimulateLocations} 
                    variant='contained'
                    color="primary" 
                    disabled={saving}>
                    Simulate Locations
                </Button>)} */}
                <Button onClick={handleClose} color="primary" disabled={saving}>
                    Cancel
                </Button>
                { isNew && (<Button onClick={handleSave} color="primary" disabled={saving}>
                    Create
                </Button>)}
            </DialogActions>
        </Dialog>
        <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
    </>
    );
}

export default RouteForm;