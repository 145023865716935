import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, TableContainer } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

function OrganizationsTable({ items, onEdit }) {
    const { t } = useTranslation();

    return (
        <div>
            {items &&
            <TableContainer>
                <Table>
                    <colgroup>
                        <col style={{width:'50%'}}/>
                        <col style={{width:'49%'}}/>
                        <col style={{width:'1%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>{t("Name")}</strong></TableCell>
                            <TableCell><strong>{t("Description")}</strong></TableCell>
                            <TableCell><strong>{t("Edit")}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(item => (
                            <TableRow key={item.id}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => { onEdit(item)}}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </div>
    );
}

export default OrganizationsTable;