import React, { useState, useEffect } from 'react';
import axios from 'axios';

const HealthCheck = ({ url }) => {
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const checkHealth = async () => {
      try {
        const response = await axios.get(url);
        console.log('Response:', response);
        if (response.status === 200) {
          console.log('Response body:', response.data);
          setStatus('ok');
        } else {
          setStatus('fail');
        }
      } catch (error) {
        console.error('Fetch error:', error);
        setStatus('fail');
      }
    };

    checkHealth();
  }, [url]);

  const getColor = () => {
    switch (status) {
      case 'loading':
        return 'gray';
      case 'ok':
        return 'lightgreen';
      case 'fail':
        return 'red';
      default:
        return 'gray';
    }
  };

  return (
    <div style={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: getColor() }}></div>
  );
};

export default HealthCheck;