import React, { useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, AppBar, Toolbar, Tooltip, IconButton, ListItemIcon, ListItemText, 
  Divider, Avatar, Menu, MenuItem } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../contexts/UserProvider';

function Header() {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const { logout } = useAuth0();
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleUserClick = (event) => {
        setAnchorElUser(event.currentTarget);
      };
      
      const handleCloseUserMenu = () => {
        setAnchorElUser(null);
      };
      
      const handleLogout = () => {
        handleCloseUserMenu();
        logout({ returnTo: window.location.origin });
      };

    return (
        <>
          <AppBar position="static">
              <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <a href='https://www.onerbox.com'  style={{ display: 'inline-block', lineHeight: '0' }}>
                    <img src={process.env.PUBLIC_URL + "/logo.png"} alt="Logo" style={{ height: '25px' }} />
                  </a>
                  <div>
                    Admin
                  </div>
                  {userInfo && (
                    <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title={t('Open profile')}>
                        <IconButton onClick={handleUserClick} sx={{ p: 0 }}>
                          <Avatar alt={userInfo?.user?.name} src={userInfo?.user?.picture} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}>
                          <MenuItem>
                          <ListItemIcon>
                              <Avatar alt={userInfo?.user?.name} src={userInfo?.user?.picture} />
                          </ListItemIcon>
                          <ListItemText sx={{pl: 1}} primary={userInfo?.user?.name} />
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={handleLogout}>
                              <ListItemIcon>
                                  <ExitToAppIcon />   
                              </ListItemIcon>
                              <ListItemText primary={t('Logout')} />
                          </MenuItem>
                      </Menu>
                    </Box>
                  )}
              </Toolbar>
          </AppBar>
        </>
    );
}

export default Header;