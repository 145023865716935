import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, TableContainer, Menu, MenuItem, Chip, Tooltip, Dialog } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { dateFormatter, shortenString } from '../controls/Formatters';
import AppSnackbar from './../AppSnackbar';
import de from 'dayjs/locale/de';


function CompaniesTable({ items }) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuId, setMenuId] = useState(null);
    const [detailsItem, setDetailsItem] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState(null);

    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setMenuId(id);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
        setMenuId(null);
    };
    
    const handleAction = (action, id) => {
        console.log(`Action ${action} for item ${id}`);
        handleClose();
        switch (action) {
            case "details":
                setDetailsItem(items.find(item => item.id === id));
                setShowDetails(true);
                break;
            case "model":
                window.open(items.find(item => item.id === id).modelUrl, '_blank');
                break;
            case "result":
                window.open(items.find(item => item.id === id).resultUrl, '_blank');
                break;
            case "matrix":
                window.open(items.find(item => item.id === id).distanceMatrixUrl, '_blank');
                break;
            case "rm":
                window.open(items.find(item => item.id === id).routingModelUrl, '_blank');
                break;
            case "log":
                window.open(items.find(item => item.id === id).logUrl, '_blank');
                break;
            default:
                break;
        }
    };

    const handleTaskClick = (taskId) => {
        navigator.clipboard.writeText(taskId);
        setSnackbarInfo({ message: t('Task Id copied to clipboard.'), severity: 'success' });
    }

    const formatItemDetails = (item) => {
        if (!item) return [];
        let details = item.details?.split(',') || [];
        details.push('Elapsed: ' + item.elapsedMilliseconds + ' ms');
        details.push('Status Time: ' + dateFormatter(item.statusTime));
        return details;
    }
    
    return (
        <div>
            {items &&
            <>
            <TableContainer>
                <Table size='small' padding='small'>
                    <colgroup>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'15%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'1%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>{t("Task Id")}</strong></TableCell>
                            <TableCell><strong>{t("Added Time")}</strong></TableCell>
                            <TableCell><strong>{t("Company Id")}</strong></TableCell>
                            <TableCell><strong>{t("License Name")}</strong></TableCell>
                            <TableCell><strong>{t("Version")}</strong></TableCell>
                            <TableCell><strong>{t("Destinations")}</strong></TableCell>
                            <TableCell><strong>{t("Vehicles")}</strong></TableCell>
                            <TableCell><strong>{t("Status")}</strong></TableCell>
                            <TableCell><strong>{t("Successful")}</strong></TableCell>
                            <TableCell><strong>{t("Finish reason")}</strong></TableCell>
                            <TableCell><strong>{t("Action")}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(item => (
                            <TableRow key={item.id}>
                                <TableCell>
                                    <Tooltip title={item.taskId}>
                                        <code onClick={() => handleTaskClick(item.taskId)}
                                            style={{ cursor: 'pointer' }}>   
                                            {shortenString(item.taskId,8)}
                                        </code>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>{dateFormatter(item.addedTime)}</TableCell>
                                <TableCell>{item.companyId}</TableCell>
                                <TableCell>{item.licenseName}</TableCell>
                                <TableCell>{item.version}</TableCell>
                                <TableCell>{item.destinations}</TableCell>
                                <TableCell>
                                    <Tooltip title={item.vehiclesId}>
                                        <Chip
                                            label={item.vehicles}
                                        />
                                    </Tooltip>
                                </TableCell>
                                <TableCell>{item.status}</TableCell>
                                <TableCell>
                                    <Chip
                                        label={item.isSuccessful ? "Yes" : "No"}
                                        style={{
                                        backgroundColor: item.isSuccessful ? 'rgb(89, 183, 80)' : 'rgb(183, 80, 80)',
                                        color: 'white'
                                        }}
                                    />
                                </TableCell>
                                <TableCell>{item.finishReason}</TableCell>
                                <TableCell>
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={(event) => handleClick(event, item.id)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id={`menu-${item.id}`}
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl) && menuId === item.id}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => handleAction("details", item.id)}>Details</MenuItem>
                                        <MenuItem onClick={() => handleAction("model", item.id)}>Model...</MenuItem>
                                        <MenuItem onClick={() => handleAction("result", item.id)}>Result...</MenuItem>
                                        <MenuItem onClick={() => handleAction("matrix", item.id)}>Distance matrix...</MenuItem>
                                        <MenuItem onClick={() => handleAction("rm", item.id)}>Routing model...</MenuItem>
                                        <MenuItem onClick={() => handleAction("log", item.id)}>Logs..</MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={showDetails} onClose={() => setShowDetails(false)}>
                <div style={{padding: '10px'}}>
                    {formatItemDetails(detailsItem).map(item => (
                        <Chip label={item} style={{margin: '2px'}} />
                    ))}
                </div>
            </Dialog>
            <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
            </>
            }
        </div>
    );
}

export default CompaniesTable;