import React, { useState, useEffect, useContext } from 'react';
import LicensesTable from './LicensesTable';
import { Button, Box, LinearProgress, Container, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ApiService from '../../../services/apiService';
import LicenseForm from './LicenseForm';
import { UserContext } from '../../../contexts/UserProvider';
import { useTranslation } from 'react-i18next';
import AppSnackbar from '../../AppSnackbar';

function Licenses() {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [items, setItems] = useState(null);
    const [isItemFormOpen, setIsItemFormOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [snackbarInfo, setSnackbarInfo] = useState(null);
    const [filterText, setFilterText] = useState('');

    useEffect(() => {
        requestItems(userInfo?.idToken);
    }, [userInfo?.idToken]); 

    const requestItems = async (idToken) => {
        setIsLoading(true);
        try {
            const response = await ApiService.getLicenses(idToken);
            response.sort((a, b) => { return a.companyId.localeCompare(b.companyId); });
            setItems(response);
        }
        catch(error) {
            setItems(null);
            setSnackbarInfo({ message: t(error.message), severity: error.severity});
        }
        setIsLoading(false);
    }
        
    const showItemForm = () => {
        setIsItemFormOpen(true);
    }

    const hideItemForm = () => {
        setIsItemFormOpen(false);
        setSelectedItem(null);
    }

    const acceptItemForm = () => {
        requestItems(userInfo?.idToken);
    };

    const handleOnEditItem = async (editItem) => {
        setIsLoading(true);
        try {
            const loadedItem = await ApiService.getLicense(userInfo?.idToken, editItem.id);
            setSelectedItem(loadedItem);
            showItemForm();
        } 
        catch(error) {
            setSnackbarInfo({ message: t(error.message), severity: error.severity});
        }
        setIsLoading(false);
    }

    const handleAddItem = () => {
        setSelectedItem(null);
        showItemForm();
    }

    const filteredItems = items?.filter(item => 
        item.companyId.toLowerCase().includes(filterText.toLowerCase()) ||
        item.name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.key.toLowerCase().includes(filterText.toLowerCase()));

    return (
        <>
        { isLoading && (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>)}
            <Container maxWidth="xl">
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <h1>{t("Licenses")}</h1>
                    <Button variant='contained' onClick={handleAddItem}>{t("Add")}</Button>
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={t("Search...")}
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <LicensesTable items={filteredItems} onEdit={handleOnEditItem} />
            </Container>
            <LicenseForm 
                open={isItemFormOpen} 
                onClose={hideItemForm} 
                onAccept={acceptItemForm} 
                editItem={selectedItem} />
            <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
        </>
    );
}

export default Licenses;