import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl, IconButton, FormHelperText } from '@mui/material';
import ApiService from '../../../services/apiService';
import AppSnackbar from '../../AppSnackbar';
import { UserContext } from '../../../contexts/UserProvider';
import DeleteButton from '../../ConfirmButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function CompanyForm({ open, onClose, onAccept, editItem }) {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [item, setItem] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [snackbarInfo, setSnackbarInfo] = useState(null);
    const [saving, setSaving] = useState(false);
    const [isNew, setIsNew] = useState(true);
    const [errors, setErrors] = useState({
        organization: '',
        companyId: '',
        companyKey: '',
        modules: [],
        details: []
    });

    useEffect(() => {
        fetchOrganizations();
      }, []); 

    useEffect(() => {
        if (editItem) {
            setItem(editItem);
            setIsNew(false);
        }
    }, [editItem]);

    const fetchOrganizations = async () => {
        try {
            const response = await ApiService.getOrganizations(userInfo?.idToken);
            const organizationNames = response.map(org => org.name);
            organizationNames.sort((a, b) => { return a.localeCompare(b); });   
            setOrganizations(organizationNames);
        } catch (error) {
            setSnackbarInfo({ message: t(error.message), severity: error.severity });
        }
    };

    const validateForm = () => {
        const newErrors = {
            organization: '',
            companyId: '',
            companyKey: '',
            modules: [],
            details: []
        };
    
        if (!item.organization) {
            newErrors.organization = 'Organization is required';
        }
        if (!item.companyId) {
            newErrors.companyId = 'Company ID is required';
        }
        if (!item.companyKey) {
            newErrors.companyKey = 'Company Key is required';
        }
        item.modules?.forEach((module, index) => {
            if (!module) {
                newErrors.modules[index] = 'Module cannot be empty';
            }
        });
        item.details?.forEach((detail, index) => {
            if (!detail) {
                newErrors.details[index] = 'Detail cannot be empty';
            }
        });
    
        setErrors(newErrors);
    
        return !newErrors.organization && 
                !newErrors.companyId && 
                !newErrors.companyKey &&
                newErrors.modules.every(e => !e) && 
                newErrors.details.every(e => !e);
    };

    const handleSave = async () => {
        if (!validateForm()) return;

        setSaving(true);
        try {
            if (isNew) {
                await ApiService.saveCompany(userInfo?.idToken, item);
                setSnackbarInfo({ message: t('Company created.'), severity: 'success' });
            } else {
                await ApiService.updateCompany(userInfo?.idToken, item);
                setSnackbarInfo({ message: t('Company updated.'), severity: 'success' });
            }
            onAccept(item);
            handleClose();
        } catch (error) {
            setSnackbarInfo({ message: t(error.message), severity: error.severity });
            setSaving(false);
        }
    };

    const handleDelete = async () => {
        setSaving(true);
        try {
            await ApiService.deleteCompany(userInfo.idToken, item);
            onAccept(null);
            handleClose();
            setSnackbarInfo({ message: t('Deleted successfully.'), severity: 'success' });
        } catch (error) {
            setSnackbarInfo({ message: t(error.message), severity: error.severity });
            setSaving(false);
        }
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const resetForm = () => {
        setItem({ id: 0, companyId: '', companyKey: '', organization: '', gdcEnabled: false, smartDeliveryMaps: false, smartDelivery: false, licensesNum: 0, modules: [], details: [], logo: '' });
        setSaving(false);
        setIsNew(true);
        setErrors({ organization: '', companyId: '', companyKey: '', modules: [], details: [] });
    };

    const handleAddModule = () => {
        setItem({ ...item, modules: [...item.modules, ''] });
    };

    const handleRemoveModule = (index) => {
        const newModules = item.modules.filter((_, i) => i !== index);
        setItem({ ...item, modules: newModules });
    };

    const handleAddDetail = () => {
        setItem({ ...item, details: [...item.details, ''] });
    };

    const handleRemoveDetail = (index) => {
        const newDetails = item.details.filter((_, i) => i !== index);
        setItem({ ...item, details: newDetails });
    };

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setItem({ ...item, logoData: reader.result.split(',')[1], logoMimeType: file.type });
        };
        reader.readAsDataURL(file);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '1000px', // Adjust the width as needed
                        maxWidth: 'none',
                    },
                }}
            >
                <DialogTitle>Edit Company</DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1, margin: '0 10px' }}>
                            <FormControl fullWidth margin="normal" error={!!errors.organization}>
                                <InputLabel>Organization</InputLabel>
                                <Select
                                    value={item?.organization ?? ''}
                                    onChange={(e) => setItem({ ...item, organization: e.target.value })}
                                >
                                    {organizations.map((org) => (
                                        <MenuItem key={org} value={org}>
                                            {org}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.organization && <FormHelperText>{errors.organization}</FormHelperText>}
                            </FormControl>
                            <TextField
                                margin="normal"
                                label="Company ID"
                                type="text"
                                fullWidth
                                value={item?.companyId || ''}
                                onChange={(e) => setItem({ ...item, companyId: e.target.value })}
                                error={!!errors.companyId}
                                helperText={errors.companyId}
                            />
                            <TextField
                                margin="normal"
                                label="Company Key"
                                type="text"
                                fullWidth
                                value={item?.companyKey || ''}
                                onChange={(e) => setItem({ ...item, companyKey: e.target.value })}
                                error={!!errors.companyKey}
                                helperText={errors.companyKey}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={item?.gdcEnabled || false} onChange={(e) => setItem({ ...item, gdcEnabled: e.target.checked })} />}
                                label="GDC"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={item?.smartDeliveryMaps || false} onChange={(e) => setItem({ ...item, smartDeliveryMaps: e.target.checked })} />}
                                label="Smart Delivery Maps"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={item?.smartDelivery || false} onChange={(e) => setItem({ ...item, smartDelivery: e.target.checked })} />}
                                label="Smart Delivery"
                            />
                            <TextField
                                margin="normal"
                                label="Licenses Num"
                                type="number"
                                fullWidth
                                value={item?.licensesNum || 0}
                                onChange={(e) => setItem({ ...item, licensesNum: e.target.value })}
                            />
                        </div>
                        <div style={{ flex: 1, margin: '0 10px', display: 'flex', flexDirection: 'column' }}>
                            <div>
                                <InputLabel>Modules</InputLabel>
                                { item?.modules?.map((module, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            margin="small"
                                            size="small"
                                            type="text"
                                            fullWidth
                                            value={module}
                                            onChange={(e) => {
                                                const newModules = [...item.modules];
                                                newModules[index] = e.target.value;
                                                setItem({ ...item, modules: newModules });
                                            }}
                                            error={!!errors.modules[index]}
                                            helperText={errors.modules[index]}
                                        />
                                        <IconButton onClick={() => handleRemoveModule(index)}>
                                            <RemoveIcon />
                                        </IconButton>
                                    </div>
                                ))}
                                <Button onClick={handleAddModule} startIcon={<AddIcon />}>
                                    Add Module
                                </Button>
                            </div>
                            <div>
                                <InputLabel>Details</InputLabel>
                                { item?.details?.map((detail, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            margin="small"
                                            size="small"
                                            type="text"
                                            fullWidth
                                            value={detail}
                                            onChange={(e) => {
                                                const newDetails = [...item.details];
                                                newDetails[index] = e.target.value;
                                                setItem({ ...item, details: newDetails });
                                            }}
                                            error={!!errors.details[index]}
                                            helperText={errors.details[index]}
                                        />
                                        <IconButton onClick={() => handleRemoveDetail(index)}>
                                            <RemoveIcon />
                                        </IconButton>
                                    </div>
                                ))}
                                <Button onClick={handleAddDetail} startIcon={<AddIcon />}>
                                    Add Detail
                                </Button>
                            </div>
                            <div>
                                <InputLabel>Logo</InputLabel>
                                
                                {item?.logoData && item?.logoMimeType && (
                                    <div style={{ padding:'15px' }}>
                                        <img src={`data:${item.logoMimeType};base64,${item.logoData}`} alt="Company Logo" style={{ maxWidth: '100px' }} />
                                    </div>
                                    
                                )}
                                <input type="file" accept="image/*" onChange={handleLogoChange} />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    { !isNew && !saving && (<DeleteButton 
                        variant="contained"
                        color="error"
                        onConfirm={handleDelete}
                        msgTitle={t("Delete company")}
                        msgText={t("Are you sure you want to delete?")}>
                        {t('Delete')}
                    </DeleteButton>)}
                    <Button onClick={handleClose} color="primary" disabled={saving}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" disabled={saving}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
        </>
    );
}

export default CompanyForm;