import React from 'react';
import { useTranslation } from 'react-i18next';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

const PlotlyRenderers = createPlotlyRenderers(Plot);

function AnalsysDataPivot({ items }) {
    const { t } = useTranslation();
    const [pivotState, setPivotState] = React.useState({
        cols: ['Service','Type'],
        rows: ['Company Id','License Name'],
        rendererName: 'Table Heatmap',
        aggregatorName: 'Sum',
        vals: ['Number'],
        

    });

    return (
        <div>
            {items &&
            <PivotTableUI
                data={items}
                onChange={s => setPivotState(s)}
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                {...pivotState}
            />
            }
        </div>
    );
}

export default AnalsysDataPivot;