import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, TableContainer, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

function RoutesTable({ items, onEdit }) {
    const { t } = useTranslation();

    const satusToColor = (number) => {
        const hex = number.toString(16);
        const color = "#" + hex + hex + hex;
        return color;
    }

    return (
        <div>
            {items &&
            <TableContainer>
                <Table>
                    <colgroup>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'50%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'1%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>{t("CompanyId")}</strong></TableCell>
                            <TableCell><strong>{t("RouteId")}</strong></TableCell>
                            <TableCell><strong>{t("Name")}</strong></TableCell>
                            <TableCell><strong>{t("Status")}</strong></TableCell>
                            <TableCell><strong>{t("Description")}</strong></TableCell>
                            <TableCell><strong>{t("View")}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(item => (
                            <TableRow key={item.id}>
                                <TableCell>{item.companyId}</TableCell>
                                <TableCell>{item.routeId}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>
                                    <Chip label={item.status} style={{backgroundColor: satusToColor(item.status)}} />
                                </TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => {onEdit(item)}}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </div>
    );
}

export default RoutesTable;